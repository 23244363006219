/* eslint-disable import/prefer-default-export */
import * as events from '../events';
import Utilities from '../utilities';
import genericEvents from './generic-events';

export const FlowModule = {
    name: 'MTN CM',
    logger: {},
    dispatcher: {},
    uiControl: {},
    tracker: {},
    integrator: {},
    territory: '',
    metadata: {},
    locale: '',
    identityToken: '',
    integratorIdentifyUrl: '',
    cancelBtns: [],
    identified: false,
    identity: '',
    identifyCalled: false,
    identifyCallFinished: false,
    internalCall: false,
    productUrl: '',
    productSlug: '',
    timeoutLimit: 180000, // 3 minutes
    trackConversions: false,
    identifyTimeout: 15,
    timeout: null,
    flowSteps: {
        SPINNER: 'spinner',
        INIT: 'init',
        CONFIRM: 'confirm',
        SUBSCRIBE: 'subscribe',
        ERROR: 'error',
        FRAUD: 'fraud',
        SUBSCRIPTION_FAILED: 'subscription failed',
        SUBSCRIPTION_SUCCEEDED: 'subscription success',
        CANCEL: 'cancel',
        TIMEOUT: 'timeout',
        WIFI: 'wifi',
    },
    pageStates: {
        SUBSCRIBE: 'subscribe',
        SUCCESS: 'success',
        CONFIRM: 'confirm',
        SPINNER: 'spinner',
        ERROR: 'error',
        CANCEL: 'cancel',
        TIMEOUT: 'timeout',
        WIFI: 'wifi',
    },

    /**
     * Method to initialise the flow. This will be binding all the event listeners
     * needed to implement the two click flow
     */
    init() {
        this.integrator.appendBaseData({ territory: this.territory });
        this.logger.debug(`Flow (${this.name}): initialising flow`);
        this.bindClickEvents();
        this.bindTrackingEvents();
        this.bindIdentifyIframeEvents();
        genericEvents.bindWifiClickEvent(this);
        genericEvents.bindFlowExitEvent(this);
        this.setFlowStep(this.flowSteps.INIT);
        this.goToFirstStep();
        if (!this.internalCall) {
            this.identifyCustomer();
        }
        this.setInactiveTimeout();
        this.setupExitHandler();
        this.handleTryForFree()
    },

    handleTryForFree() {
        //if a query string of triedfree is in query parameters then disable the button to avoid a loop
        const urlParams = new URLSearchParams(window.location.search);
        const tryForFree = urlParams.get('triedfree');
        if (tryForFree) {
            this.uiControl.hideElement(this.uiControl.controls.tryForFree)
        }
    },

    /**
     * Set the current step of the flow
     *
     * @param {string} flowStep
     */
    setFlowStep(flowStep) {
        this.logger.debug(`Flow (${this.name}): changing step from ${this.step} to ${flowStep}`);
        this.step = flowStep;

        this.setPageStateForFlowStep(flowStep);
    },

    /**
     * Set the current state of the page
     *
     * @param {string} flowStep
     */
    setPageStateForFlowStep(flowStep) {
        switch (flowStep) {
        case this.flowSteps.INIT:
        // eslint-disable-next-line no-fallthrough
        case this.flowSteps.SUBSCRIBE:
            this.uiControl.setPageState(this.pageStates.SUBSCRIBE);
            break;
        case this.flowSteps.CONFIRM:
            this.uiControl.setPageState(this.pageStates.CONFIRM);
            break;
        case this.flowSteps.SPINNER:
            this.uiControl.setPageState(this.pageStates.SPINNER);
            break;
        case this.flowSteps.CANCEL:
            this.uiControl.setPageState(this.pageStates.CANCEL);
            break;
        case this.flowSteps.TIMEOUT:
            this.uiControl.setPageState(this.pageStates.TIMEOUT);
            break;
        case this.flowSteps.WIFI:
            this.uiControl.setPageState(this.pageStates.WIFI);
            break;
        case this.flowSteps.SUBSCRIPTION_SUCCEEDED:
            this.uiControl.setPageState(this.pageStates.SUCCESS);
            break;
        case this.flowSteps.ERROR:
        case this.flowSteps.FRAUD:
        case this.flowSteps.SUBSCRIPTION_FAILED:
        default:
            this.uiControl.setPageState(this.pageStates.ERROR);
            break;
        }
    },

    /**
     * If the user's been on the page for more than 60s without an action, time him out!
     */
    setInactiveTimeout() {
        // if we have a previous timeout, reset it
        this.clearInactiveTimeout();

        this.timeout = window.setTimeout(() => {
            this.logger.debug(`Flow (${this.name}): handling inactive timeout`);
            this.setFlowStep(this.flowSteps.TIMEOUT);
            this.tracker.track(events.PAGE_TIMEOUT);
            this.uiControl.showErrorMessage('Votre session a expiré. Veuillez cliquer ci-dessous pour actualiser la page et réessayer.', 'Réessayer');
        }, this.timeoutLimit);
        this.logger.debug(`Flow (${this.name}): set inactive timeout ${this.timeout}`);
    },

    /**
     * Remove previous inactive timeout
     */
    clearInactiveTimeout() {
        if (this.timeout) {
            this.logger.debug(`Flow (${this.name}): clearing timeout ${this.timeout}`);
            window.clearTimeout(this.timeout);
            this.timeout = null;
        }
    },

    handleTryForFreeEvent() {
        window.onbeforeunload = null;
        let redirectUrl = this.productUrl;
        const pageQuery = (window.location.href.indexOf('?') !== -1) ? window.location.href.split('?')[1] + '&' : ''
        redirectUrl = `${this.productUrl}?${pageQuery}locale=${this.locale}&triedfree=true`;

        this.logger.debug(`Flow (${this.name}): redirecting to product`, redirectUrl);
        this.tracker.track(events.REDIRECT_TO_PRODUCT, {
            data: {
                redirectUrl: redirectUrl,
            },
        });

        window.location.assign(redirectUrl);
    },

    /**
     * Binding the click events on the first button (#flow-subscribe) and on the
     * confirm button (#flow-confirm)
     */
    bindClickEvents() {
        this.logger.debug(`Flow (${this.name}): binding click events`);

        // listen for first click
        this.dispatcher.addEventListener(events.FIRST_CLICK, () => {
            // reset inactive timeout
            this.setInactiveTimeout();
            this.logger.debug(`Flow (${this.name}): handling first button click - subscribe`);
            this.setFlowStep(this.flowSteps.CONFIRM);
            this.tracker.track(events.FIRST_CLICK);
            this.uiControl.hideElement(this.uiControl.controls.tryForFree)
            this.uiControl.hideElement(this.uiControl.controls.subscribe);
            this.uiControl.showElement(this.uiControl.controls.confirm);
        });

        // listen for second click
        this.dispatcher.addEventListener(events.SECOND_CLICK, () => {
            this.logger.debug(`Flow (${this.name}): handling second button click - confirm`);
            this.uiControl.hideElement(this.uiControl.controls.confirm);
            this.uiControl.hideElement(this.uiControl.controls.tryForFree);
            this.setFlowStep(this.flowSteps.SPINNER);
            this.uiControl.showElement(this.uiControl.controls.spinner);

            // here we clear the timeout entirely since the user has to wait for the result
            this.clearInactiveTimeout();
            this.tracker.track(events.SECOND_CLICK);
            this.dispatcher.dispatchEvent(events.PRE_SUBSCRIBE);

            Utilities.waitForConditionAndExecute(
                this.isIdentifyCallFinished.bind(this),
                this.subscribeUser.bind(this),
            );
        });

        this.dispatcher.addEventListener(events.TRY_FOR_FREE, this.handleTryForFreeEvent.bind(this))
    },

    /**
     * Add listener for postMessages
     */
    bindIdentifyIframeEvents() {
        this.logger.debug(`Flow (${this.name}): binding iframe events`);
        window.addEventListener('message', this.onMessage.bind(this), false);
    },

    showSubscribeButton() {
        this.uiControl.showElement(this.uiControl.controls.subscribe);
    },

    /**
     * Binding the tracking events
     */
    bindTrackingEvents() {
        this.dispatcher.addEventListener(events.FRAUD_DETECTED, (event) => {
            this.tracker.track(events.FRAUD_DETECTED, {
                data: {
                    reference: event.reference,
                    status_code: event.status_code,
                },
            });
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: event.reference,
                status_code: event.status_code,
            });
        });

        this.dispatcher.addEventListener(events.SUBSCRIPTION_FAILED, (event) => {
            this.tracker.track(events.SUBSCRIPTION_FAILED, {
                data: {
                    reference: event.reference,
                    status_code: event.status_code,
                    error: event.error,
                    response_string: event.response_string,
                },
            });
        });

        this.dispatcher.addEventListener(events.SUBSCRIPTION_SUCCEEDED, (event) => {
            this.tracker.track(events.SUBSCRIPTION_SUCCEEDED, {
                data: {
                    subscription: event.jwt,
                    reference: event.reference,
                },
            });
            const ts = Utilities.getQueryParameter(null, 'ts');

            if (this.trackConversions && ts !== 'SM') {
                const redirector = () => {
                    this.redirectToProduct(event.jwt);
                };

                this.uiControl.showElement(this.uiControl.controls.success.container);
                window.setTimeout(redirector.bind(this), 2500);
                this.uiControl.displayMessage('Please wait while you are redirected to the service');
                this.tracker.conversion({
                    subscription: event.jwt,
                });
            } else {
                this.redirectToProduct(event.jwt);
            }
        });
    },

    /**
     * Redirect to product with an auth token
     *
     * @param {string} jwt
     */
    redirectToProduct(jwt) {
        // remove unload message
        window.onbeforeunload = null;

        this.tracker.track(events.REDIRECT_TO_PRODUCT);

        const contentID = Utilities.getQueryParameter(null, 'cid');

        let productUrl = this.productUrl;
        if (!productUrl.startsWith("http")) {
            productUrl = "https://" + productUrl;
        }
        let redirectUrl = new URL(productUrl);
        if (contentID) {
            redirectUrl.pathname = Utilities.pathJoin(redirectUrl.pathname, contentID)
        }

        redirectUrl.searchParams.set("auth", jwt);
        redirectUrl.searchParams.set("locale", this.locale);

        this.logger.debug(`Flow (${this.name}): redirecting to product`, redirectUrl.toString());

        window.location.assign(redirectUrl.toString());
    },

    /**
     * Check the query parameters and change flow to either the subscribe or confirm button
     */
    goToFirstStep() {
        this.setFlowStep(this.flowSteps.SUBSCRIBE);
        this.showSubscribeButton();
    },

    /**
    * Start the identification process
    */
    identifyCustomer() {
        this.logger.debug(`Flow (${this.name}): identifying customer`);

        this.identifyCalled = true;

        setTimeout(this.checkIdentify.bind(this), this.identifyTimeout * 1000);
        this.integrator.heIframeIdentify(
            {
                return: window.location.href,
                metadata: {
                    check_subscription: 'true',
                },
            }
        );
    },

    subscribeUser() {
        if (this.identified) {
            const onSuccess = (response) => {
                this.logger.debug(`Flow (${this.name}): received integrator response`, response);

                if (response.status === 'SUCCESS') {
                    this.setFlowStep(this.flowSteps.SUBSCRIPTION_SUCCEEDED);
                    if (response.new) {
                        this.dispatcher.dispatchEvent(events.SUBSCRIPTION_SUCCEEDED, {
                            jwt: response.jwt,
                            reference: response.reference,
                        });
                    } else {
                        this.tracker.track(events.SUBSCRIPTION_EXISTS, {
                            data: {
                                jwt: response.jwt,
                                reference: response.reference,
                            },
                        });
                        this.redirectToProduct(response.jwt);
                    }
                } else {
                    this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
                    this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                        reference: response.reference,
                        error: response.message || null,
                    });
                    this.uiControl.hideElement(this.uiControl.controls.spinner);
                    if (response.status_code === 402) {
                        this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
                    }
                }
            };

            const onError = (response) => {
                this.uiControl.hideElement(this.uiControl.controls.spinner);

                if (response.status_code === 403) {
                    this.logger.debug(`Flow (${this.name}): fraud detected`, response);
                    this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
                    this.setFlowStep(this.flowSteps.FRAUD);
                    this.dispatcher.dispatchEvent(events.FRAUD_DETECTED, {
                        reference: response.reference,
                        status_code: response.status_code,
                    });
                } else {
                    this.logger.error(`Flow (${this.name}): received error from integrator`, response);
                    this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
                    this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                        reference: response.reference,
                        status_code: response.status_code,
                        error: response.message || null,
                        response_string: response.response_string || null,
                    });

                    this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
                }
            };
            const data = {
                identity: this.identity,
                fraud_detection_id: this.metadata.fraud_transaction_id,
                metadata: this.metadata,
                mixpanel_tracking_id: this.metadata.event_tracking_id,
            }
            this.logger.info('user is identified; attempting subscription with backend subscription');
            if (this.metadata['page_attributes-mtn-v2-subscribe']) {
                this.integrator.LPSubscribeRequest(data, 'lp/v2/he/subscribe', onSuccess, onError);
            } else {
                this.integrator.LPSubscribeRequest(data, 'lp/he/subscribe', onSuccess, onError);
            }
        } else {
            this.tracker.track(events.SHOW_WIFI);
            this.uiControl.showWifiFlowWithRefreshPage();
        }
    },

    /**
    * Is the identification call finished?
    *
    * @return {boolean}
    */
    isIdentifyCallFinished() {
        return this.identifyCallFinished;
    },

    /**
     * Check the identification process on completion of the timeout (this.identifyTimeout)
     */
    checkIdentify() {
        this.logger.debug(`Flow (${this.name}): identification timeout`);
        this.timeoutReached = true;

        if (!this.isIdentifyCallFinished()) {
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.setFlowStep(this.flowSteps.IDENTIFY_FAILURE);
            this.tracker.track(events.IDENTIFY_ERROR, {
                data: {
                    error: `request timeout reached - ${this.identifyTimeout}s`,
                },
            });
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
        }
    },

    onMessage(event) {
        this.logger.debug(`Flow (${this.name}): received iframe message`, event);

        const originDomain = event.origin.split('://');
        const integratorDomain = this.integratorIdentifyUrl.split('://');
        const baseIntegratorDomain = integratorDomain[1].split('?')[0];
        const validDomains = [
            baseIntegratorDomain,
        ];

        if (typeof originDomain[1] === 'undefined' || originDomain[1] === '' || validDomains.indexOf(originDomain[1]) === -1) {
            this.logger.debug(`Flow (${this.name}): failed to validate message origin.`, originDomain, validDomains);
            return;
        }
        const heResponse = event.data;
        const trackingData = {
            identity: heResponse.identity,
            identified: heResponse.identified,
            reference: heResponse.reference,
        };

        if (heResponse.status === 'SUCCESS' && heResponse.status_code === 200) {
            this.identity = heResponse.identity;
            this.identified = heResponse.identified;
            this.metadata.identity = heResponse.identity;

            if (heResponse.identified) {
                this.tracker.track(events.IDENTIFY_SUCCESS, { data: trackingData });
            } else {
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.setFlowStep(this.flowSteps.WIFI);
                this.tracker.track(events.IDENTIFY_FAILURE, { data: trackingData });
                this.dispatcher.dispatchEvent(events.IDENTIFY_FAILURE);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.showWifiFlowWithRefreshPage();
            }
        } else if (heResponse.status_code == 302) {
            this.onSubscriptionExists(heResponse)
        } else { // 'ERROR'
            this.setFlowStep(this.flowSteps.IDENTIFY_FAILURE);
            this.dispatcher.dispatchEvent(events.IDENTIFY_FAILURE);
            this.tracker.track(events.IDENTIFY_ERROR, { data: trackingData });
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.uiControl.hideElement(this.uiControl.controls.subscribe);
            this.uiControl.hideElement(this.uiControl.controls.confirm);
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
        }
        this.identifyCallFinished = true;
    },

    onSubscriptionExists(response){
        this.tracker.track(events.SUBSCRIPTION_EXISTS, {
            data: {
                reference: response.reference,
            },
        });
        this.redirectToProduct(response.identity);
    },

    // Event for exit button clicked.
    setupExitHandler() {
        const button = [].slice.call(document.getElementsByClassName('exit'));
        button.forEach((elem) => {
            elem.addEventListener('click', (e) => {
                e.preventDefault();
                window.location.href = 'https://google.co.uk';
            });
        });
    },
};

window.FlowModule = FlowModule;
